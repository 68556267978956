// noinspection GraphQLUnresolvedReference

import gql from 'graphql-tag';

const MUTATION = gql`
  mutation DossierTypeListHandlePositionFieldByBlockId($blockId: ID!, $data: JSON) {
    successful: dossierTypeListHandlePositionFieldByBlockId(blockId: $blockId, data: $data)
  }
`;

export default MUTATION;
